<script setup lang="ts">
import HomeLayout from "~/layouts/HomeLayout.vue"
import { SUPPORTED_PAGES_ENUM } from "~/types/breadcrumb"

definePageMeta({
    colorMode: "light",
})
/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */
const route = useRoute()
const title = computed(() => {
    const path = route.path
    if (path.includes("/offers")) {
        return "Ofertas"
    } else if (path.includes("blog")) {
        return "Blog"
    }

    return "Todos los productos en nuestra tienda"
})
const page = computed(() => {
    const path = route.path
    if (path.includes("offers")) {
        return SUPPORTED_PAGES_ENUM.Offers
    } else if (path.includes("blog")) {
        return SUPPORTED_PAGES_ENUM.Blog
    }
    return SUPPORTED_PAGES_ENUM.Store
})

const {
    data: bestSellingProducts,
    get: getBestSellingProducts,
    loading: bestSellingProductsLoading,
} = useOFetchCustom("public/best_sellers")

const actualPostStore = useActualPostStore()
/**
 *
 *  Life cicle events
 *
 *
 */
onMounted(() => {
    if ("blog" in route.params) {
        actualPostStore.fetchPost(route.params.blog as string)
    }
    getBestSellingProducts(undefined)
})
/**
 *
 *  Functions section
 *
 *
 */
</script>

<template>
    <HomeLayout
        :title="title"
        :currentPage="page"
        withoutParentStyles
        customBanner="./img/banners/banner-home-tiodomin.webp"
        customBannerMobile="./img/banners/tiodomin.webp"
    >
        <template #main>
            <slot></slot>
        </template>
        <template #most_sold>
            <MoreProducts
                title="Lo más vendido!"
                subtitle="Lo que más gusta!"
                class="max-container"
                :products="bestSellingProducts?.data ?? []"
                v-if="
                    (bestSellingProducts?.data?.length > 0 &&
                        !bestSellingProductsLoading) ||
                    bestSellingProductsLoading
                "
            />
        </template>
    </HomeLayout>
</template>

<style scoped></style>
